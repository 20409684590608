<template>
  <div style="position: relative">
    <el-backtop target=".el-main"></el-backtop>
    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>
    <div class="search">
      <div style="display: flex">
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px; margin-right: 10px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input placeholder="请输入手机号" v-model="phone"></el-input>
        <el-input
          placeholder="请输入要查询的钱包地址"
          style="width: 260px"
          v-model="walletUrl"
        ></el-input>
        <div>
          <el-button type="primary" @click="searchList">搜索</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>
      </div>
    </div>

    <div class="table_con">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
      >
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="180">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="travelId" label="行程ID" width="180">
        </el-table-column>
        <el-table-column prop="startTime" label="发车时间" width="180">
        </el-table-column>
        <el-table-column prop="startImg" label="发车图片" width="180">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.startImg"
              :preview-src-list="[scope.row.startImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" sortable label="结束时间" width="180">
        </el-table-column>
        <el-table-column prop="endImg" label="行程结束图片" width="180">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.endImg"
              :preview-src-list="[scope.row.endImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="startPlace" label="行程起点" width="200">
        </el-table-column>
        <el-table-column prop="endPlace" label="行程终点" width="200">
        </el-table-column>
        <el-table-column prop="consumeTime" label="行程耗时" width="130">
        </el-table-column>
        <el-table-column prop="distance" label="行驶距离" width="200">
        </el-table-column>
        <el-table-column prop="travelStatus" label="行程状态">
        </el-table-column>
        <el-table-column prop="cple" label="待奖励CPLE" width="150">
        </el-table-column>
        <el-table-column prop="pledge" label="质押量" width="130">
        </el-table-column>
        <el-table-column prop="cpleaddress" label="钱包地址" width="230">
        </el-table-column>
        <el-table-column prop="txid" label="Txid" width="230">
        </el-table-column>
        <el-table-column
          prop="rewardTime"
          sortable
          label="操作时间"
          width="180"
        >
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      tableData: [],

      valueArea: "",
      options: [],
      phone: "",
      walletUrl: "",

      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "CPLE每日奖励下发",
        },
        {
          name: "历史奖励",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 200, //要传过去的数据 每页多少条数据
      pageSizes: [200, 500, 1000, 5000, 10000, 20000, 50000, 100000],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    this.phone = this.$route.query.phone || "";
    this.valueArea = this.$route.query.phoneArea || "";

    showLoading();
    const opt = {
      url: reqUrl.getRewardList,
      method: "POST",
      params: JSON.stringify({
        cpleAddress: "",
        field1: "",
        field2: "reward_time",
        nonce: "",
        order1: "",
        order2: "0",
        pageNum: 0,
        pageSize: 200,
        phone: this.$route.query.phone || "",
        phoneArea: this.phoneAreaROLE || this.$route.query.phoneArea,
        startTime: "",
        rewardStatus: "0",
        sign: "",
        token: "",
        type: "-1",
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          res.data.data.list.map(
            (item) => (item.endTime = format_time_date(item.endTime))
          );
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      if (this.phone || this.walletUrl || this.valueArea) {
        showLoading();
        const opt = {
          url: reqUrl.getRewardList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: this.walletUrl,
            field1: "",
            field2: "reward_time",
            nonce: "",
            order1: "",
            order2: "0",
            pageNum: 0,
            pageSize: 200,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            startTime: "",
            rewardStatus: "0",
            sign: "",
            token: "",
            type: "-1",
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              res.data.data.list.map(
                (item) => (item.endTime = format_time_date(item.endTime))
              );
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getRewardList,
        method: "POST",
        params: JSON.stringify({
          cpleAddress: this.walletUrl,
          field1: "",
          field2: "reward_time",
          nonce: "",
          order1: "",
          order2: "0",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          rewardEndTime: "",
          rewardStartTime: "",
          rewardStatus: "0",
          sign: "",
          token: "",
          type: "-1",
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            res.data.data.list.map(
              (item) => (item.endTime = format_time_date(item.endTime))
            );
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    reset() {
      if (this.phone || this.walletUrl || this.valueArea) {
        this.phone = "";
        this.walletUrl = "";
        this.valueArea = "";

        // 重置后重新执行
        const opt = {
          url: reqUrl.getRewardList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: "",
            field1: "",
            field2: "reward_time",
            nonce: "",
            order1: "",
            order2: "0",
            pageNum: 0,
            pageSize: 0,
            phone: "",
            phoneArea: this.phoneAreaROLE,
            startTime: "",
            rewardStatus: "0",
            sign: "",
            token: "",
            type: "-1",
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              res.data.data.list.map(
                (item) => (item.endTime = format_time_date(item.endTime))
              );
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-input {
    width: 200px;
    margin-right: 10px;
  }
}

.pagination {
  margin-bottom: 10px;
}
</style>
